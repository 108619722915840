import React from "react";
import { Link } from 'gatsby';
import { login, isAuthenticated } from '../services/auth';

import Layout from "../components/layout";

import '../styles/documentation.scss';

const Documentation = () => {
  if (!isAuthenticated()) {
    login('/documentation');
    return <p>Redirecting to login…</p>;
  }
  
  return (
    <Layout title="Docs">
      <div className="documentation-page">
        <div className="documentation-listing">
          <div className="documentation-group">
            <h2 className="documentation-heading">
              Editorial Process
            </h2>
            <h3>How Do I?</h3>
            <ul>
              <li><Link to="/documentation/claim-collection">Claim a Collection</Link></li>
              <li><Link to="/documentation/tracking-active-assignments">Track My Active Assignments</Link></li>
              <li><Link to="/documentation/submitting-content">Submit My Content</Link></li>
            </ul>
            <h3>PDR Editorial Web Interface</h3>
            <ul>
              <li><Link to="/documentation/dashboard">Dashboard</Link></li>
              <li><Link to="/documentation/prospects">Collections – Prospective Collections</Link></li>
              <li><Link to="/documentation/assignments">Collections – Assignments</Link></li>
            </ul>
          </div>
          <div className="documentation-group">
            <h2 className="documentation-heading">
              Editorial Guidelines
            </h2>
            <ul>
              <li><i>TK</i></li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Documentation;